<template>
  <div class="drawer-operation">
    <a-tabs type="card" class="tab-label" v-model="imageType">
      <a-tab-pane key="wst" tab="文生图" class="tab-container">
        <OperationTitle :title="'描述您想要生成的图片'"/>
        <a-textarea class="text-container" placeholder="请输入提示词"/>
        <div class="button-container">
          <a-button type="link" block>生成提示词</a-button>
          <a-button type="link" block>清空</a-button>
        </div>
        <OperationTitle :title="'宽高比例'"/>
        <ScaleCard/>
        <OperationTitle :title="'反向提示词'"/>
        <a-textarea class="text-container re-text-container" placeholder="请输入提示词"/>
        <OperationTitle :title="'NIJI动漫风格'"/>

        <div class="img-card-container">
          <img :src="'https://ai.kuyunai.cc/public_web/static/images/workAi/mj-bg.png'" class="img-selected"/>
          <img :src="'https://ai.kuyunai.cc/public_web/static/images/workAi/niji-bg.png'"/>
        </div>
        <HighOperation/>
      </a-tab-pane>
      <a-tab-pane key="tst" tab="图生图" class="tab-container">

      </a-tab-pane>
    </a-tabs>

    <div class="operation-footer">
      <div class="flex-container">
        <p>消耗：1次</p>
        <p>
          <icon-font type="icon-mianzeshuoming" class="icon"/>
          免责声明
        </p>
      </div>
      <span @click="createImage">生成图片</span>
    </div>


  </div>

</template>
<script>

export default ({
  components: {
    OperationTitle: () => import('@/views/application/drawer/component/OperationTitle.vue'),
    ScaleCard: () => import('@/views/application/drawer/component/ScaleCard.vue'),
    HighOperation: () => import('@/views/application/drawer/component/HighOperation.vue')
  },
  data() {
    return {
      imageType: 'wst'
    }
  },
  methods: {
    createImage() {
      let config = {
        imageType: this.imageType
      }
      this.$emit('createImage', config)
    }
  }
})

</script>

<style lang="scss">

.drawer-operation {
  display: flex;
  flex-direction: column;
  height: 100%;
  //background: #f6f7f9 !important;

  .tab-label {
    height: calc(100vh - 125px);
    overflow-y: auto;
  }

  .tab-container {
    padding: 10px 15px;

    p {
      margin: 15px 0;
      font-weight: 600;
    }

    .text-container {
      border: none;
      background-color: rgb(246, 247, 249);
      height: 118px;
      min-height: 118px;
      max-height: 118px;
      overflow-y: hidden;
      resize: none;
      border-radius: 10px 10px 0 0;
    }

    .re-text-container {
      border-radius: 10px;
    }

    .button-container {
      display: flex;
      background-color: rgb(246, 247, 249);
      border-radius: 0 0 10px 10px;
      border-top: 1px solid #e3e4e5;
    }

    .img-card-container {
      justify-content: space-evenly;
      display: flex;

      img {
        height: 84px;
        width: 84px;
        border-radius: 6px;
        cursor: pointer
      }

      img:hover {
        border: 2px solid red;
      }

      .img-selected {
        border: 2px solid red;
        box-shadow: 0 0 10px 5px #fff;
      }

    }
  }

  .operation-footer {
    background: #fff;
    flex: 1;
    border-top: 1px solid #e3e4e5;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    border-radius: 10px 10px 0 0;

    .flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }

      p:last-child {
        font-size: 12px;
        color: #999;
      }

      p:first-child {
        background-image: linear-gradient(to right, #ff7e5f, #feb47b);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }

    span {
      margin: 15px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(90deg, #343749, #536976);
      color: #fff;
      border-radius: 4px;
      padding: 5px 0;
      cursor: pointer;
    }
  }


  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border: none;
    margin-right: 0;
    width: 160px;
    background: rgba(217, 217, 217, 0.4);
    border-radius: 0;
    text-align: center;
  }

  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border: none;
    width: 160px;
    background: #fff;
    border-radius: 0;

  }

  .ant-tabs-bar {
    border: none;
  }
}
</style>